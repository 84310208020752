import React from "react";

const IconAlert = ({ size = "20" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#F21F5E" />
      <path
        d="M10.0793 11.5777C10.9328 11.5777 11.6311 10.8793 11.6311 10.0258V5.37022C11.6311 4.5167 10.9328 3.81836 10.0793 3.81836C9.22574 3.81836 8.5274 4.5167 8.5274 5.37022V10.0258C8.5274 10.8793 9.22574 11.5777 10.0793 11.5777Z"
        fill="white"
      />
      <path
        d="M10.8552 16.2333C11.282 16.2333 11.6311 15.8841 11.6311 15.4573V13.9055C11.6311 13.4787 11.282 13.1295 10.8552 13.1295H9.30334C8.87657 13.1295 8.5274 13.4787 8.5274 13.9055V15.4573C8.5274 15.8841 8.87657 16.2333 9.30334 16.2333H10.8552Z"
        fill="white"
      />
    </svg>
  );
};
export default IconAlert;
