export enum PostHogEvent {
  // eslint-disable-next-line no-unused-vars
  RESELLER_LOGIN = "reseller_login",
  // eslint-disable-next-line no-unused-vars
  ORGANIZATION_LOGIN = "organization_login",
  QUOTE_START = "quote_start",
  QUOTE_COMPLETE = "quote_complete",
  QUOTE_REQUEST_CONVERT = "quote_request_convert",
  QUOTE_CONVERTED = "quote_converted",
}

export enum PostHogFeatureFlag {
  SHOW_CLAIMS_AT_RESELLER = "show-claims-at-reseller",
  RESELLERS_MANAGING_CLIENTS = "resellers-managing-clients",
}
