import { atom } from "jotai/index";
import {
  ResellerClient,
  ResellerClientCreationPayload,
} from "../types/reseller-clients";
import { atomWithRefresh, loadable } from "jotai/utils";
import { getAccessToken } from "../services/amplify";
import { atomEffect } from "jotai-effect";
import { showToast } from "../components/toast";

const getResellerClientEffect = atomEffect((get, set) => {
  if (get(loadableResellerClientAtom).state === "loading") {
    set(loadingResellerClientAtom, true);
  } else {
    set(loadingResellerClientAtom, false);
  }
});

export const loadingResellerClientAtom = atom<boolean>(false);

export const resellerClientSearchParamAtom = atom<string>("");

export const getResellerClientAtom = atomWithRefresh<Promise<any>>(
  async (get) => {
    const searchParam = get(resellerClientSearchParamAtom);
    if (!searchParam) {
      return Promise.resolve({ data: [] });
    }
    const url = `${process.env.REACT_APP_API_PRODUCTS}/internal/v3/client-information/?search=${searchParam}`;
    const accessToken = await getAccessToken();

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch products");
    }

    return response.json();
  },
);

export const loadableResellerClientAtom = loadable(getResellerClientAtom);

export const resellerClientAtom = atom<ResellerClient[]>((get) => {
  get(getResellerClientEffect);
  const loadableResellerClient = get(loadableResellerClientAtom);
  if (
    loadableResellerClient.state === "loading" ||
    loadableResellerClient.state === "hasError"
  ) {
    return [];
  }

  const rawClients = loadableResellerClient.data?.data || [];

  return rawClients.map((rawClient: any) => ({
    id: rawClient.id,
    contracts: 0,
    companyName: rawClient.company_name,
    corporateName: rawClient.corporate_name,
    domainNameUrl: rawClient.domain_name_url,
    firstName: rawClient.first_name,
    lastName: rawClient.last_name,
    primaryContactEmail: rawClient.primary_contact_email,
    resellerId: rawClient.reseller_id,
  }));
});

export const createResellerClientAtom = atom(
  null,
  async (get, set, payload: ResellerClientCreationPayload): Promise<void> => {
    const url = `${process.env.REACT_APP_API_PRODUCTS}/internal/v3/client-information/`;
    const accessToken = await getAccessToken();

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          domain_name_url: payload.domainName,
          company_name: payload.companyName,
          corporate_name: payload.corporateName,
        }),
      });
      if (response.status === 409) {
        showToast({
          title: "Error",
          description: "Already exists a company with this name or email",
          status: "error",
        });
        return;
      }
      if (!response?.ok) {
        throw new Error();
      }
      set(createResellerClientRequestAtom, response.json());
    } catch (error) {
      showToast({
        title: "Error",
        description: "Failed to create reseller client",
        status: "error",
      });
    }
  },
);

export const createResellerClientRequestAtom = atom<Promise<any> | null>(null);

export const loadableCreateResellerClientRequestAtom = loadable(
  createResellerClientRequestAtom,
);

export const createdResellerStreamAtom = atom<Promise<any>>(async (get) => {
  const loadableCreateResellerClientRequest = get(
    loadableCreateResellerClientRequestAtom,
  );
  if (loadableCreateResellerClientRequest.state !== "hasData") {
    return null;
  }

  const requestResponse = loadableCreateResellerClientRequest.data;

  return requestResponse;
});

const loadableCreatedResellerStreamAtom = loadable(createdResellerStreamAtom);

export const createdResellerAtom = atom<ResellerClient | null>((get) => {
  get(createdResellerEffectAtom);
  const loadableCreatedReseller = get(loadableCreatedResellerStreamAtom);
  if (loadableCreatedReseller.state !== "hasData") {
    return null;
  }

  const rawClient = loadableCreatedReseller.data;
  if (rawClient === null) {
    return null;
  }

  return {
    id: rawClient.data.id,
    contracts: 0,
    companyName: rawClient.data.company_name,
    corporateName: rawClient.data.corporate_name,
    domainNameUrl: rawClient.data.domain_name_url,
    firstName: rawClient.data.first_name,
    lastName: rawClient.data.last_name,
    primaryContactEmail: rawClient.data.primary_contact_email,
    resellerId: rawClient.data.reseller_id,
  };
});

const createdResellerEffectAtom = atomEffect((get, set) => {
  const createdResellerClient = get(createdResellerAtom);

  if (createdResellerClient !== null) {
    set(
      resellerClientSearchParamAtom,
      createdResellerClient.companyName as string,
    );
  }
});

export const loadingUpdateResellerClientAtom = atom(false);

export const updateResellerClientAtom = atom(
  null,
  async (
    get,
    set,
    clientInfoId: string,
    payload: ResellerClientCreationPayload,
    onSuccess: () => void,
  ): Promise<Response | void> => {
    const url = `${process.env.REACT_APP_API_PRODUCTS}/internal/v3/client-information/${clientInfoId}/`;
    const accessToken = await getAccessToken();

    set(loadingUpdateResellerClientAtom, true);
    try {
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          domain_name_url: payload.domainName,
          company_name: payload.companyName,
          corporate_name: payload.corporateName,
          primary_contact_email: payload.emailAddress,
          first_name: payload.firstName,
          last_name: payload.lastName,
        }),
      });
      if (response.status === 409) {
        showToast({
          title: "Error",
          description: "Already exists a company with this name or email",
          status: "error",
        });
        set(loadingUpdateResellerClientAtom, false);
        return;
      }
      if (!response?.ok) {
        throw new Error();
      }
      showToast({
        title: "Client Updated",
        description: "Client updated successfully",
        status: "success",
      });
      onSuccess();
      set(loadingUpdateResellerClientAtom, false);
    } catch (error) {
      showToast({
        title: "Error",
        description: "Failed to update reseller client",
        status: "error",
      });
      set(loadingUpdateResellerClientAtom, false);
    }
  },
);
