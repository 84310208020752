import { atom } from "jotai";
import { ProductFilterOptions } from "../types/product-filters";
import { loadable } from "jotai/utils";
import { getAccessToken } from "../services/amplify";
import { atomEffect } from "jotai-effect";

export const loadingProductFiltersAtom = atom(false);

const productFiltersAtomEffect = atomEffect((get, set) => {
  if (get(loadableGetProductFiltersAtom).state === "loading") {
    set(loadingProductFiltersAtom, true);
  } else {
    set(loadingProductFiltersAtom, false);
  }
});

const getProductFiltersAtom = atom(async (get) => {
  const url = `${process.env.REACT_APP_API_PRODUCTS}/internal/v3/products/filter-options/`;
  const accessToken = await getAccessToken();

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch products");
  }

  const res: any = await response.json();
  return res.data;
});

const loadableGetProductFiltersAtom = loadable(getProductFiltersAtom);

export const productFiltersAtom = atom<ProductFilterOptions>((get) => {
  const productFiltersLoadable = get(loadableGetProductFiltersAtom);
  get(productFiltersAtomEffect);
  if (
    productFiltersLoadable.state === "loading" ||
    productFiltersLoadable.state === "hasError"
  ) {
    return {
      deviceTypes: [],
      deviceValues: [],
      serviceFees: [],
      coverageLength: [],
      coverageTypes: [],
    };
  }

  return {
    deviceValues: productFiltersLoadable.data.device_values,
    serviceFees: productFiltersLoadable.data.service_fees,
    coverageLength: productFiltersLoadable.data.coverage_lengths.filter(
      (coverage: string) => coverage !== "monthly",
    ),
    coverageTypes: productFiltersLoadable.data.loss_type_categories.map(
      (lossTypeCategory: string[]) =>
        lossTypeCategory.map((lossType) =>
          lossType.replace("Malfunctions", "Mechanical Breakdown"),
        ),
    ),
    deviceTypes: productFiltersLoadable.data.item_types.map(
      (itemType: {
        id: string;
        name: string;
        item_groups: {
          id: string;
          name: string;
        }[];
      }) => {
        return {
          key: itemType.id,
          label: itemType.name,
          children: itemType.item_groups.map((itemGroup) => {
            return {
              key: itemGroup.id,
              label: itemGroup.name,
            };
          }),
        };
      },
    ),
  };
});
