import React from "react";

import IconAlert from "../icon/components/icon-alert";
import IconCircleSuccess from "../icon/components/icon-circle-success";

import { Slide, toast, ToastContainer } from "react-toastify";
import { ToastProps } from "react-toastify/dist/types";

const statusComponents = {
  success: <IconCircleSuccess size="30" />,
  error: <IconAlert size="30" />,
};

type Props = {
  title: string;
  description: string;
  status: "success" | "error";
};

export const ToastProvider = () => (
  <ToastContainer
    position="bottom-right"
    autoClose={3000}
    closeButton={false}
    hideProgressBar
    newestOnTop
    transition={Slide}
  />
);
const Toast = ({ title, description, status }: Props) => {
  return (
    <div
      className={`transform transition-all duration-300 ease-in-out rounded-xl py-3 px-5 shadow-lg bg-slate-900 flex`}
    >
      <div className="flex items-center justify-center pr-5">
        {statusComponents[status]}
      </div>
      <div className="w-[24rem]">
        <p className="text-white font-bold truncate">{title}</p>
        <p className="text-grey-125 font-semibold truncate" title={description}>
          {description}
        </p>
      </div>
    </div>
  );
};

export const showToast = (
  props: Props,
  toastProps?: Partial<ToastProps>,
): string | number => {
  return toast(<Toast {...props} />, { ...toastProps });
};

export default ToastProvider;
