import React from "react";
import { Dialog as DialogBase, DialogPanel } from "@headlessui/react";
import c from "classnames";

import Spinner from "../spinner/spinner";

type Props = {
  isLoading: boolean;
};

const FullScreenLoading = ({ isLoading }: Props) => {
  return (
    <DialogBase
      open={isLoading}
      onClose={() => {}}
      className="relative z-[1000]"
    >
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4 bg-white bg-opacity-80">
        <DialogPanel className={c("space-y-4 ")}>
          <Spinner />
        </DialogPanel>
      </div>
    </DialogBase>
  );
};

export default FullScreenLoading;
