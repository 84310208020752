import React, { useEffect, useState } from "react";

import { useAtomValue } from "jotai";

import { loadableFeatureFlagsAtom } from "../../lib/feature-flags";
import {
  loadableAsyncRequestResponseCreatedQuoteAtom,
  loadingQuotesPendingForReviewAtom,
} from "../../lib/quotes";
import { loadableCreateResellerClientRequestAtom } from "../../lib/reseller-clients";
import { loadingProductFiltersAtom } from "../../lib/product-filters";
import FullScreenLoading from "../full-screen-loading";

const LoadingModal = () => {
  const [isLoading, setIsLoading] = useState(false);

  // These atoms to be called in the beginning of the applications
  // they return loadableAtoms so the check should be on the state
  const loadableFeatureFlags = useAtomValue(loadableFeatureFlagsAtom);
  const loadableCreateResellerClientRequest = useAtomValue(
    loadableCreateResellerClientRequestAtom,
  );
  const loadableAsyncRequestResponseCreatedQuote = useAtomValue(
    loadableAsyncRequestResponseCreatedQuoteAtom,
  );

  // These atoms are based on atomEffect implementations and should return booleans
  const loadingQuotesPendingForReview = useAtomValue(
    loadingQuotesPendingForReviewAtom,
  );
  const loadingProductFilters = useAtomValue(loadingProductFiltersAtom);

  useEffect(() => {
    if (
      loadableFeatureFlags.state === "loading" ||
      loadableCreateResellerClientRequest.state === "loading" ||
      loadableAsyncRequestResponseCreatedQuote.state === "loading" ||
      loadingQuotesPendingForReview ||
      loadingProductFilters
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    loadableFeatureFlags,
    loadingQuotesPendingForReview,
    loadableCreateResellerClientRequest,
    loadableAsyncRequestResponseCreatedQuote,
    loadingProductFilters,
  ]);

  return <FullScreenLoading isLoading={isLoading} />;
};

export default LoadingModal;
